import { render, staticRenderFns } from "./FeatureLink.vue?vue&type=template&id=9586adf2&"
import script from "./FeatureLink.vue?vue&type=script&lang=js&"
export * from "./FeatureLink.vue?vue&type=script&lang=js&"
import style0 from "@cognitoforms/styles/website/flex-columns.scss?vue&type=style&index=0&prod&lang=scss&"
import style1 from "./FeatureLink.vue?vue&type=style&index=1&id=9586adf2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@3.6.0_react-dom@16.14.0_react@16.14.0_vue-template-compiler@2.7.15_webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports