<template>
	<component :is="link ? 'router-link' : 'div'" :to="link" class="feature-link no-underline p-4" :class="`feature-link--${flexDirection}`">
		<div v-if="$slots.illustration" class="feature-link__image"><slot name="illustration" /></div>
		<inline v-else-if="illustration" :svg="`complex-icons/${illustration}`" class="w-3 matins" />
		<div class="matins" :class="{ 'title-row' : flexDirection }">
			<h3 v-if="title" class="mt-3">{{ title }}</h3>
			<div v-if="$slots.default" class="feature-link__slot" :class="{ 'mt-3' : title }">
				<slot></slot>
			</div>
		</div>
	</component>
</template>

<script>
	import componentMountedEvent from '@cognitoforms/shared-components/src/mixins/content-component-mounted-event';

	export default {
		name: 'CFeatureLink',
		mixins: [componentMountedEvent],
		props: {
			title: { type: String, default: '' },
			illustration: { type: String, default: '' },
			link: { type: String, default: null },
			flexDirection: { type: String, default: 'column' }
		}
	};
</script>

<!--
	The CSS in flex-columns is not used by this component but is always used just outside of it.
	For simplicity we're including it here.
-->
<style lang="scss" src="@cognitoforms/styles/website/flex-columns.scss"></style>

<style lang="scss">
.feature-link {
	display: flex;
	transition: background-color .25s;

	&__image amp-img,
	&__image img {
		width: auto;
		height: 80px;
	}

	&__image img {
		display: block;
	}

	&--column {
		flex-direction: column;
	}

	&--row {
		align-items: flex-start;
		flex-direction: row;
	}

	h3 {
		transition: color .25s;
	}

	.i-complex {
		flex-shrink: 0;
		width: 80px;
		height: auto;
		margin-right: 1rem;

		@include ie {
			height: 80px;
		}
	}
}

// Only add hover styling when FeatureLink is an anchor
a.feature-link:hover {
	background-color: $arnold;

	h3 {
		color: $deep;
	}
}

// IE Styling Madness
@include ie {

	[class*='flex--3-cols'] .feature-link {
		flex: 1 1 100%;
	}
}
</style>